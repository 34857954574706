<template>
  <a-layout-header :style="{ padding: '0 16px', background: '#fff', position: 'fixed', zIndex: 1, width: '100%', borderBottom: '1px solid #f5f6f7' }">
    <a-row type="flex" :gutter="16">
      <a-col flex="1 1 200px">
        <span class="logo" @click="handleToPage('/')">ECharts图表示例</span>
      </a-col>
      <a-col :spinning="loading" flex="0 1 800px" style="text-align: right">
        <div v-if="login">
          <span>您好；{{ user.nickname }}！</span>
          <a-divider type="vertical" />
          <a-button @click="showWeixin">联系我</a-button>
          <a-divider type="vertical" />
          <a-button @click="handleToPage('/user/home')">个人中心</a-button>
          <a-divider type="vertical" />
          <a-popconfirm
              title="是否退出当前账号？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleConfirmLogout"
          >
            <a-button>退出</a-button>
          </a-popconfirm>
        </div>
        <div v-if="!login">
          <a-button @click="showWeixin">联系我</a-button>
          <a-divider type="vertical" />
          <a-button @click="handleToPage('/user/register')">注册</a-button>
          <a-divider type="vertical" />
          <a-button @click="handleToPage('/user/login')">登录</a-button>
        </div>
      </a-col>
    </a-row>
    <a-modal v-model:visible="visible" title="联系我" @ok="handleOk">
      <p style="text-align: center;"><img src="https://static.muzimuzi.com/weixin.jpg" alt="微信" height="450"></p>
      <p>需要进交流群的加我微信，验证消息：echarts。</p>
      <p>数据错误反馈，数据侵权，意见反馈等问题都可以通过邮件联系或直接加微信。</p>
      <p>Email：wx@muzimuzi.com</p>
    </a-modal>
  </a-layout-header>
</template>

<script>
import { isLogin, clearToken, clearRefreshToken, getRefreshToken, getToken } from "../utils/auth"
import { getUserInfo } from "../api/user"
import axios from 'axios'

export default {
  data() {
    return {
      visible: false,
      loading: false,
      login: false,
      user: {
        createdAt: '',
        nickname: '',
        phoneNumber: ''
      }
    }
  },
  created() {
    this.loginStatus()
  },
  methods: {
    loginStatus() {
      this.loading = true
      if (isLogin()) {
        getUserInfo().then(res => {
          this.user = res.data
          this.login = true
          this.loading = false
        })
      }else {
        this.login = false
        this.loading = false
      }
    },
    // 页面跳转
    handleToPage(url) {
      this.$router.push(url)
    },
    handleConfirmLogout() {
      const headerConfig = {
        headers: {
          'token': getToken(),
          'refreshToken': getRefreshToken()
        }
      }
      axios.post('/apis/user/token/delete', {}, headerConfig).then(() => {
        clearToken()
        clearRefreshToken()
        this.$router.push('/user/login')
      })
    },
    showWeixin() {
      this.visible = true
    },
    handleOk() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
.logo {
  cursor: pointer;
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #1890FF;
}
</style>
