import request from '../plugins/request'

// 用户注册
export function userRegister(data) {
    return request({
        url: '/user/register',
        method: 'post',
        data
    })
}

// 获取邮箱注册验证码
export function userRegisterEmailCode(data) {
    return request({
        url: '/user/email/register',
        method: 'post',
        data
    })
}

// 用户登录
export function userLogin(data) {
    return request({
        url: '/user/token/login',
        method: 'post',
        data
    })
}

// 刷新token
export function updateToken(data) {
    return request({
        url: '/user/token/update',
        method: 'post',
        data
    })
}

// 退出账号
export function deleteToken(data) {
    return request({
        url: '/user/token/delete',
        method: 'post',
        data
    })
}

// 用户信息
export function getUserInfo() {
    return request({
        url: '/user/info',
        method: 'get'
    })
}

// 用户权限
export function getUserAuthorities() {
    return request({
        url: '/user/authorities',
        method: 'get'
    })
}
