<template>
  <a-layout-footer :style="{ marginTop: '20px', textAlign: 'center' }">
    www.muzimuzi.com ©2022 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #777;">粤ICP备20051068号</a>
  </a-layout-footer>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

</style>
