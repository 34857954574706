import axios from 'axios'
import {message} from "ant-design-vue";
import {
    isLogin,
    getToken,
    clearToken,
    getRefreshToken,
    clearRefreshToken,
    setToken,
    setRefreshToken
} from "@/utils/auth"

const service = axios.create({
    baseURL: "https://api.muzimuzi.com",
    timeout: 20000,
});

let isRefreshing = false
let requests = []

// 请求拦截
service.interceptors.request.use(
    config => {
        if (isLogin()) {
            config.headers['token'] = getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 响应拦截
service.interceptors.response.use(
    response => {
        const res = response.data
        const { config } = response
        if (res.code === "510") {
            if (!isRefreshing) {
                isRefreshing = true
                let headerConfig = {
                    headers: { 'refreshToken': getRefreshToken() }
                }
                return axios.post('https://api.muzimuzi.com/user/token/update', {}, headerConfig).then(res => {
                    const {accessToken, refreshToken} = res.data.data
                    setToken(accessToken)
                    setRefreshToken(refreshToken)
                    isRefreshing = false
                    config.headers['token'] = accessToken
                    requests.forEach(cb => cb(accessToken))
                    requests = []
                    return service(config)
                }).catch(() => {
                    clearToken()
                    clearRefreshToken()
                    return Promise.reject(new Error(res.msg || '系统错误'))
                }).finally(() => {
                    isRefreshing = false
                })
            }else {
                return new Promise((resolve) => {
                    requests.push((accessToken) => {
                        config.headers['token'] = accessToken
                        resolve(service(config))
                    })
                })
            }
        }
        if (res.code === "509") {
            clearToken()
            clearRefreshToken()
        }
        if (res.code !== "0" && res.code !== "510") {
            message.warning(res.msg)
            return Promise.reject(new Error(res.msg || '系统错误'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        message.warning(error.message)
        return Promise.reject(error)
    }
)

export default service
